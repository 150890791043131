import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/setup/custom',
    name: 'SetupCustom',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "group-foo" */ '../articles/CustomSetup.vue'),
    meta:{
      title: "Setup - Custom CMS"
    },
    props: route => ({
      test: console.log(route.query),
      assistantId: route.query.assistant_id
    }
    )
  },
  {
    path: '/setup/wordpress',
    name: 'CMSSetupWordpress',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "group-foo" */ '../articles/CMSSetupWordpress.vue'),
    meta:{
      title: "Setup - Wordpress CMS"
    },
    props: route => ({
      test: console.log(route.query),
      assistantId: route.query.assistant_id
    }
    )
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
