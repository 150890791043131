<template>
  <div class="full">
    <h1 class="text-center pa-4">Evenstad Developer Support</h1>
    <h2>Setup</h2>
    <div class="d-flex flex-column align-start">
      <v-btn text to="/setup/custom/">Chat Component setup on Custom CMS</v-btn>
      <v-btn text to="/setup/wordpress/">Chat Component setup on Wordpress CMS</v-btn>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>

<style scoped>

</style>
